import React, { useEffect } from 'react'
import { requestApi } from '../helpers/api'
import { NextPage } from 'next'
import { redirect } from '../helpers/redirect'
import getNextConfig from 'next/config'
import { AuthWrapper } from '../ui/Auth'

const { publicRuntimeConfig } = getNextConfig()

const Login: NextPage = () => {
  const checkAuth = async () => {
    try {
      const result = await requestApi('get', '/Users/me')
      if (result === undefined) {
        window.location.href = `${publicRuntimeConfig.API_AUTH}`
      } else {
        redirect('/')
      }
    } catch {
      window.location.href = `${publicRuntimeConfig.API_AUTH}`
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return <AuthWrapper></AuthWrapper>
}
export default Login

import { styled, StyledComponent } from 'linaria/react'
import { BUTTON_TYPES_TO_BACKGROUNDS, DEFAULT_TEXT_COLOR } from './constants'
import { css } from 'linaria'
import React from 'react'

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background: #fff;
  box-shadow: 0 4px 19.5556px rgba(63, 114, 91, 0.1);
  border-radius: 7px;
  padding: 40px;
  flex-direction: column;
  height: 260px;
  width: 312px;
  text-align: center;
  position: relative;
`

interface InputWrapperProps extends React.HTMLAttributes<HTMLElement> {
  isShow?: boolean
  margin?: string
}

export const InputWrapper: StyledComponent<InputWrapperProps> = styled.div`
  margin-bottom: ${(p) => p.margin};
  display: ${(p) => (p.isShow ? 'block' : 'none')};
  & > div,
  & > button {
    width: 100%;
  }

  :last-child {
    margin-bottom: 0;
  }
`

InputWrapper.defaultProps = {
  isShow: true,
  margin: '16px',
}

export const AuthWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 5%;
  top: 0;
  left: 0;
`

export const Description = styled.div`
  padding: 24px 0 24px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const LoginInput = styled.input`
  font-family: SF Pro Display Mono, sans-serif;
  font-size: 22px;
  border: 0;
  letter-spacing: 3px;
  outline: 0;
  padding: 0;
  width: 214px;
`

export const CodeInput = styled(LoginInput)`
  text-align: center;
`

export const PhonePrefix = styled.div`
  font-size: 22px;
  letter-spacing: 3px;
`

export const PhoneInputWrapper = styled.div`
  display: flex;
`

export const BackButtonClass = css`
  :before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    margin-top: -6px;
    border-right: 7px solid ${DEFAULT_TEXT_COLOR};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  :after {
    content: '';
    position: absolute;
    left: 14px;
    top: 50%;
    margin-top: -6px;
    border-right: 7px solid ${BUTTON_TYPES_TO_BACKGROUNDS.default};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
`

export const LoginTitle = css`
  margin: 0;
  flex: 0 0 36px;
`

export const LoginForm = styled.form`
  width: 100%;
`
export const LoginPhoneTitle = css`
  letter-spacing: 3px;
  white-space: nowrap;
`
